@import url(//fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap);
@import url(//fonts.googleapis.com/earlyaccess/phetsarath.css);
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

